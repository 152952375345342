import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

export const PartnersGrid = ({ title, teaser }) => {
  const intl = useIntl();
  const style = {
    whiteSpace: "pre-line",
  };
  PartnersGrid.propTypes = {
    title: PropTypes.string,
    teaser: PropTypes.string,
  };
  const partners = useStaticQuery(graphql`
    {
      crownpeak: file(relativePath: { eq: "partner/crownpeak.png" }) {
        publicURL
      }
      coremedia: file(relativePath: { eq: "partner/coremedia.png" }) {
        publicURL
      }
      dynamicyield: file(relativePath: { eq: "partner/dynamicyield.png" }) {
        publicURL
      }
      iaap: file(relativePath: { eq: "partner/iaap.png" }) {
        publicURL
      }
      netlify: file(relativePath: { eq: "partner/netlify.png" }) {
        publicURL
      }
      formcentric: file(relativePath: { eq: "partner/formcentric.png" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="container pt-md-4 my-4">
      <div className="row mb-4 reveal">
        <div className="col-12 d-flex flex-column align-items-center text-center">
          <h2>{title}</h2>
          <p style={style}>{teaser}</p>
        </div>
      </div>
      <div className="row">
        <Partner
          logoUrl={partners.crownpeak.publicURL}
          name={intl.formatMessage({ id: "partner.crownpeak" })}
          text={intl.formatMessage({ id: "partner.crownpeak.text" })}
          linkTo={intl.formatMessage({ id: "partner.crownpeak.link" })}
          linkToText={intl.formatMessage({
            id: "partner.crownpeak.link.text",
          })}
        />
        <Partner
          logoUrl={partners.coremedia.publicURL}
          name={intl.formatMessage({ id: "partner.coremedia" })}
          text={intl.formatMessage({ id: "partner.coremedia.text" })}
          linkTo={intl.formatMessage({ id: "partner.coremedia.link" })}
          linkToText={intl.formatMessage({
            id: "partner.coremedia.link.text",
          })}
        />
        <Partner
          logoUrl={partners.dynamicyield.publicURL}
          name={intl.formatMessage({ id: "partner.dynamicyield" })}
          text={intl.formatMessage({ id: "partner.dynamicyield.text" })}
        />
        <Partner
          logoUrl={partners.iaap.publicURL}
          name={intl.formatMessage({ id: "partner.iaap" })}
          text={intl.formatMessage({ id: "partner.iaap.text" })}
        />
        <Partner
          logoUrl={partners.netlify.publicURL}
          name={intl.formatMessage({ id: "partner.netlify" })}
          text={intl.formatMessage({ id: "partner.netlify.text" })}
        />
        <Partner
          logoUrl={partners.formcentric.publicURL}
          name={intl.formatMessage({ id: "partner.formcentric" })}
          text={intl.formatMessage({ id: "partner.formcentric.text" })}
        />
      </div>
    </div>
  );
};

export const Partner = ({ logoUrl, name, text, linkTo, linkToText }) => (
  <div className="col-12 col-md-6 col-lg-4">
    <div className="logo-card partners flex-column reveal pl-2 pr-2 pb-2 mb-3 bg-white">
      <img src={logoUrl} alt={name} />
      <h3 className="d-none">{name}</h3>
      <p className="h6 text-center">{text}</p>
      {linkTo && (
        <Link to={linkTo} className="btn">
          {linkToText}
        </Link>
      )}
    </div>
  </div>
);

Partner.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  linkToText: PropTypes.string,
};
